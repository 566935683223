import React, { useState } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PostCard from "../components/post-card";
import { Button, Flex, Box } from "@chakra-ui/core";
import Sidebar from "../components/Sidebar";

// TODO: compress and improve static images

export default function Home() {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { frontmatter: { type: { eq: "team" } } }
        limit: 9
      ) {
        totalCount
        edges {
          node {
            id
            frontmatter {
              title
              author
            }
            fields {
              slug
            }
            excerpt
          }
        }
      }
    }
  `);
  const [page, setPage] = useState(0);
  const [prevTrigger, setPrevTrigger] = useState(false);
  const [nextTrigger, setNextTrigger] = useState(false);
  const [flip, setFlip] = useState(false);
  const allData = data.allMarkdownRemark.edges;
  const limitedData = data.allMarkdownRemark.edges.slice(
    page * 3,
    3 * (page + 1)
  );
  let olderPostButton;
  if (allData.length > 3 * (page + 1)) {
    olderPostButton = (
      <Button
        color="brand.mango"
        variant="link"
        onClick={() => {
          setPrevTrigger(true);
        }}
      >
        Older posts
      </Button>
    );
  } else {
    olderPostButton = (
      <Button color="brand.mango" variant="link">
        <Link to="/posts">See all posts</Link>
      </Button>
    );
  }
  return (
    <Layout>
      <SEO title="Home" />
      <Box width={["auto", "42rem"]}>
        {limitedData.map(({ node }, i) => (
          <PostCard
            key={node.id}
            title={node.frontmatter.title}
            author={node.frontmatter.author}
            slug={node.fields.slug}
            excerpt={node.excerpt}
            custom={i % 3} //Change the name
            prevTrigger={prevTrigger}
            loadPrev={() => {
              setPrevTrigger(false);
              setPage(page + 1);
            }}
            nextTrigger={nextTrigger}
            loadNext={() => {
              setNextTrigger(false);
              setPage(page - 1);
            }}
            flipSides={bool => {
              setFlip(bool);
            }}
            flip={flip}
          />
        ))}
        <Flex justifyContent="space-between">
          {page == 0 ? (
            <Flex></Flex>
          ) : (
            <Button
              color="brand.mango"
              variant="link"
              onClick={() => {
                setNextTrigger(true);
              }}
            >
              Newer posts
            </Button>
          )}
          {olderPostButton}
        </Flex>
      </Box>
      <Sidebar />
    </Layout>
  );
}
